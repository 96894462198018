@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #0d1039;
  font-family: "Archivo";
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, #cb912e 0%, #f5bd1e 100%);
  border-radius: 10px;
}
.home-slider .slick-slide img
{
    display: block;
    height: 100dvh;
}
 
