/* LogoCarousel.css */
.carousel-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
}

.logo-slide {
  display: flex;
  justify-content: center;
  align-items: center;
   
  padding: 10px;  
  box-sizing: border-box; /* Ensure padding doesn't affect width calculation */
}

.logo-image {
  max-width: 100%;
  width:100%;
  
  object-fit: contain;
  transition: transform 0.3s; /* Smooth transition for hover effect */
}

@media (max-width: 600px) {
  .logo-slide {
    padding: 5px; /* Smaller padding for smaller screens */
  }
}

.logo-slide:hover .logo-image {
  transform: scale(1.05); /* Slightly enlarge image on hover */
}
